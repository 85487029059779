.mainheader {
  border-bottom: 1px solid #1A1A1A;
  background: rgba(0, 0, 0, 0.70);

  .innerheader {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .disconnectbtn {
      color: #FE0000;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.015px;
      border: 1px solid #FE0000;
      background: #000;
      display: flex;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;

    }

    .btn-launch {
      border: 1px solid #FE0000;
      background: #000;
      box-shadow: 2px 2px 0px 0px #FE0000;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 46px;


      h6 {
        padding: 10px 12px;
        color: #FE0000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        border-right: 1px solid #FE0000;
        background: #000;
        width: 100%;
        text-align: center;
      }

      svg {
        width: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .twice-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }

  .dropdown {
    .mycheckbox {
      gap: 10px !important;

      .innercheckboxpara {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .dropdown-toggle {
      border: 1px solid #2D2D2D;
      background: #000;
      border-radius: 0;
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      padding: 0px 12px;
      min-height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 21px;
      transition: 0.3s linear;

      &[aria-expanded="true"] {
        border: 1px solid #FFF;

        svg {
          transform: rotate(180deg);
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #FFF;
      padding: 0;
      border-radius: 0;
      margin-top: 5px;
      left: unset !important;
      right: 0 !important;

      .mycheckbox {
        margin: 0;
      }

      a {
        border-bottom: 1px solid #2D2D2D;
        background: #0D0D0D;
        padding: 12px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }


}

@media (max-width:600px){
  .mainheader .innerheader .btn-launch h6{
    display: none;
  }
  .mainheader .innerheader .btn-launch{
    height: 40px;
    padding: 0;
  }
}