.main-banner {
    padding-top: 40px;
    padding-bottom: 70px;
    min-height: 100vh;

    .twice-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        h6 {
            color: #FFF;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
        }

        .twice-btns {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
          }
        
          .dropdown {
            .dropdown-toggle {
              border: 1px solid #2D2D2D;
              background: #000;
              border-radius: 0;
              color: #FFF;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: uppercase;
              padding: 0px 12px;
              min-height: 37px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 21px;
              transition: 0.3s linear;
              &[aria-expanded="true"]{
                border: 1px solid #FFF;
        
                svg{
                  transform: rotate(180deg);
                }
              }
        
              &::after {
                display: none;
              }
            }
        
            .dropdown-menu {
              border: 1px solid #FFF;
              padding: 0;
              border-radius: 0;
              margin-top: 5px;
              left: unset !important;
              right: 0 !important;
            
        
              .mycheckbox{
                margin: 0;
              }
        
              a {
                border-bottom: 1px solid #2D2D2D;
                background: #0D0D0D;
                padding: 12px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }

        .custom-tab-bar {
            background: #050505;

            a {
                background: #050505;
                padding: 6px 11px;
                color: #A2A4A6;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                position: relative;

                &.active {
                    background: #FFF;
                    color: #000;
                }
            }
        }
    }

    .bottom-content {
        .mainnet-part {
            border: 1px solid #1B1B1B;
            background: #050505;
            padding: 30px 20px;

            .heading {
                h6 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.4px;
                    text-transform: uppercase;
                    margin-bottom: 24px;
                }
            }

            .bottom-boxes {
                // display: grid;
                // grid-template-columns: auto auto auto;
                // align-items: center;
                // gap: 10px;
                // flex-wrap: wrap;

                display: flex;
                grid-template-columns: max-content;
                align-items: normal;
                gap: 10px;
                flex-wrap: wrap;
                justify-content: space-evenly;

                .single-item {
                    border: 1px solid #1B1B1B;
                    background: #0D0D0D;
                    padding: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    // width: 100%;
                    width: 49%;

                    .text-item {
                        p {
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            margin-bottom: 10px;
                        }

                        h6 {
                            color: #FFF;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            letter-spacing: -0.22px;
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            span {

                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 120%;
                                letter-spacing: -0.14px;
                            }
                        }

                        .red-text {
                            color: #FE0000;
                        }

                        .green-text {
                            color: #28E664;
                        }
                    }
                }
            }

        }
    }

    .bottom-twice-graph {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        margin-top: 20px;

        .graph-parent {
            border: 0.956px solid #1B1B1B;
            background: #000;
            padding: 30px 20px;

            h6 {
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.4px;
                text-transform: uppercase;
                margin-bottom: 32px;
            }

        }
    }

    .bottom-twice-box {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        margin-top: 20px;

        .single-box {
            border: 1px solid #1B1B1B;
            background: #050505;
            padding: 30px 20px;

            .main-head {
                margin-bottom: 24px;

                h6 {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.4px;
                    text-transform: uppercase;
                }
            }

            .inside-box {
                border-bottom: 1px solid #1B1B1B;
                background: #0D0D0D;
                padding: 30px 25px;

                .upper-text {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #1B1B1B;

                    .inner-text {
                        p {
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            margin-bottom: 10px;
                        }

                        h6 {
                            color: #FFF;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            letter-spacing: -0.22px;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            span {

                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 120%;
                                letter-spacing: -0.14px;
                            }

                            .red-text {
                                color: #FE0000;
                            }

                            .green-text {
                                color: #28E664;
                            }
                        }
                    }

                    .dropdown {
                        .dropdown-toggle {
                            background-color: transparent;
                            border: none;
                            box-shadow: none;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            border: 1px solid #1B1B1B;
                            background: #050505;
                            box-shadow: 12px 12px 30px 0px rgba(0, 0, 0, 0.55);
                            padding: 0;
                            min-width: 253px;
                            right: 0 !important;
                            left: unset !important;
                            max-height: 500px;
                            overflow-y: auto;
                            &::-webkit-scrollbar{
                                display: none;
                            }

                            .inside-coins {
                                .single-coin {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    border-bottom: 1px solid #1B1B1B;
                                    padding: 15px;

                                    .left-text {
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;

                                        img{
                                            width: 21px;
                                            height: 21px;
                                        }

                                        p {
                                            color: #BCBCBC;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 21px;
                                            letter-spacing: 0.28px;
                                        }
                                    }

                                    h6 {
                                        color: #FFF;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }
                            }
                        }
                    }
                }

                .bottom-text {
                    padding-top: 30px;

                    p {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        margin-bottom: 10px;
                    }

                    h6 {
                        color: #FFF;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.22px;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        span {

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            letter-spacing: -0.14px;
                        }

                        .red-text {
                            color: #FE0000;
                        }

                        .green-text {
                            color: #28E664;
                        }
                    }
                }

            }

        }
    }

    .reward-part {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 10px;
        margin-top: 20px;
        border: 1px solid #1B1B1B;
        background: #050505;
        padding: 30px 20px;

        .single-reward {
            border: 1px solid #1B1B1B;
            background: #0D0D0D;
            padding: 25px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .left-side {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .text {
                p {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 10px;
                }

                h6 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -0.22px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    span {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        letter-spacing: -0.14px;
                    }

                    .green-text {
                        color: #28E664;
                    }

                    .red-text {
                        color: #FE0000;
                    }
                }
            }
        }


        .dropdown {
            .dropdown-toggle {
                background-color: transparent;
                border: none;
                box-shadow: none;

                &::after {
                    display: none;
                }

                svg {
                    transform: rotate(180deg);
                }

                &[aria-expanded="true"] {
                    svg {
                        transform: rotate(0deg);
                    }
                }
            }

            .dropdown-menu {
                border: 1px solid #1B1B1B;
                background: #050505;
                box-shadow: 12px 12px 30px 0px rgba(0, 0, 0, 0.55);
                padding: 0;
                min-width: 253px;
                right: 0 !important;
                left: unset !important;

                .inside-coins {
                    .single-coin {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #1B1B1B;
                        padding: 15px;

                        .left-text {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            p {
                                color: #BCBCBC;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 21px;
                                letter-spacing: 0.28px;
                            }
                        }

                        h6 {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }


}



.dropdownbadges {
    .dropdown-menu {
        min-width: 572px !important;
        transform: unset !important;
        top: 30px !important;

        .inside-coins {
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            overflow: hidden;

            .single-coin {
                flex: 0 0 50%;
                width: 100%;
            }
        }
    }
}









.apexcharts-legend-text {
    color: #FFF !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important;
    margin-bottom: 35px !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
    gap: 20px !important;
}

#chart {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

@media (max-width:1024px) {

    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
        inset: auto 0px 1px;
        position: absolute !important;
        flex-wrap: nowrap !important;
        white-space: nowrap !important;
        align-items: center !important;
        justify-content: flex-start !important;
    }

    .apexcharts-legend-text {
        display: contents !important;
    }



}


@media (max-width:600px) {
    .main-banner .bottom-content .mainnet-part .bottom-boxes {
        grid-template-columns: auto;
    }

    .main-banner .bottom-content .mainnet-part .bottom-boxes .single-item {
        padding: 15px;
    }

    .main-banner .bottom-content .mainnet-part .bottom-boxes .single-item .text-item h6 {
        font-size: 16px;
    }

    .main-banner .bottom-content .mainnet-part .bottom-boxes .single-item .text-item h6 span {
        font-size: 12px;
    }

    .main-banner .bottom-content .mainnet-part .bottom-boxes .single-item .text-item p {
        font-size: 14px;
    }

    .main-banner .twice-items {
        flex-direction: column;
        gap: 25px;
    }

    .main-banner .twice-items h6 {
        font-size: 22px;
    }
    .main-banner .bottom-content .mainnet-part .bottom-boxes .single-item {
        border: 1px solid #1B1B1B;
        background: #0D0D0D;
        padding: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .main-banner .twice-items .custom-tab-bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .main-banner .bottom-content .mainnet-part {
        padding: 15px;
    }

    .main-banner .bottom-twice-graph {
        grid-template-columns: auto;
    }

    .main-banner .bottom-twice-box {
        grid-template-columns: auto
    }

    .main-banner .reward-part {
        grid-template-columns: auto;
        padding: 15px;
    }

    .main-banner .bottom-twice-graph .graph-parent {
        padding: 15px;
    }

    .main-banner .bottom-twice-box .single-box {
        padding: 15px;
    }

    .datatable-section {
        padding: 15px !important;
    }
    .main-banner .twice-items .twice-btns{
        flex-direction: column-reverse;
    }
}


.apexcharts-toolbar {
    display: none !important;
}
