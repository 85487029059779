.datatable-section {
    border: 1px solid #1B1B1B;
    background: #050505;
    padding: 32px;
    margin-top: 20px;
    .main-heading{
        margin-bottom: 24px;
        h6{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            letter-spacing: 0.4px;
            text-transform: uppercase;
        }
    }
    .table-responsive{
        border: 1px solid #1B1B1B;
        background: #0D0D0D;
        table{
            th{
                border: none;
                padding: 10px 20px;
                white-space: nowrap;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                background-color: transparent;
                vertical-align: middle;
            }
            td{
                border: none;
                padding: 10px 20px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.28px;
                vertical-align: middle;
                background-color: transparent;
                border-top: 1px solid #1B1B1B;

                .twice-text{
                    h6{
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; 
                        letter-spacing: 0.1px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                    p{
                        color: #AAA;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0.28px;
                    }
                }
                .para{
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }


            }
        }
        .footer-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            // border-top: 1px solid #F2F2F2;
            background-color: transparent;

            .left-f {
                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #A0A1A1;
                }
            }

            .right-f {
                .page-link:focus {
                    box-shadow: none;
                    background-color: unset;
                }

                .page-link:hover {
                    box-shadow: none;
                    background-color: unset;
                }

                .page-item {
                    background-color: transparent !important;
                    border: none !important;
                }

                .page-link {
                    border: none;
                    color: #A2A4A6 !important;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 15px */
                    background-color: #000;
                    border-radius: 0 !important;

                    &.active {
                        background: #fff;
                        color: #000;
                    }
                }

                .page-item.active .page-link {
                    background: #fff;
                    color: #000 !important;

                    &:hover {
                        background-color: #fff !important;
                        color: #000 !important;
                    }
                }

                .page-item:first-child .page-link {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 100%;
                    color: #A0A1A1;
                }
            }
        }
    }
}