@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';


html {
  scroll-behavior: smooth;
}

body {
  background: #000;
  height: 100vh;
  font-family: 'Space Grotesk', sans-serif;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}


iframe {
  display: none !important;
}



.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



// iframe {
//   position: unset !important;
//   top: 0px !important;
//   left: 0px !important;
//   width: 0px !important;
//   height: 0px !important;
// }

.recaptcha-wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 30px auto 0 auto;
}


button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}


.custom-container {
  max-width: 1270px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.material-textfield {
  position: relative;
  width: 100%;
}

.material-textfield label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  padding: 0 6.4rem 0 1.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  color: #343434;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 90%;
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border: 1px solid #303030;
  background: #000;
  border-radius: 0px;
  padding: 17px 18px;
  width: 100%;
  box-shadow: none !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117%;

  &::placeholder {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 117%;
  }
}

.material-textfield input:focus+label {
  color: #fff;
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: unset;
}

.material-textfield input:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: unset;
}

.modal-backdrop {
  opacity: unset !important;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
}


.btn-login {
  border: 1px solid #fff;
  background: #000;
  box-shadow: 4px 4px 0px 0px #fff;
  padding: 15px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 16px */
  text-transform: uppercase;
}

.material-textfield {
  margin-bottom: 14px !important;
}




.mycheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 19px;
  margin-bottom: 30px;

  .innercheckboxpara {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin: 0 !important;

  }

  .innercheckbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .innercheckbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .innercheckbox .innercheckmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    transition: all 0.2s ease;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.00);
  }

  .innercheckbox input:checked~.innercheckmark {
    background: #fff;
    border: 1px solid transparent;
  }



  .innercheckbox .innercheckmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 5.5px;
    height: 12px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .innercheckbox input:checked~.innercheckmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}



.btn-launch {
  border: 1px solid #FFF;
  background: #000;
  box-shadow: 2px 2px 0px 0px #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 46px;
  cursor: pointer;

  h6 {
    padding: 10px 12px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border-right: 1px solid #FFF;
    background: #000;
    width: 100%;
    text-align: center;
  }

  svg {
    width: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cal {
  .rdrCalendarWrapper {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 99;
    border: 1px solid #1B1B1B;
    background: #050505;
    border-radius: 0;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrDayNumber span {
    color: #fff !important;
  }
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    border-radius: 0 !important;
  }
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background-color: #fff !important;

  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: #000 !important;
  }
}