.wrapper-header{
    .mainheader .innerheader .disconnectbtn{
        display: none;
    }
}

.login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
    .login-card{
        max-width: 558px;
        width: 100%;
        border: 1px solid #1B1B1B;
        background: #000;
       
        .heading-text{
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            border-bottom: 1px solid #1F1F1F;
            background: #000;
            padding: 22px 30px;

        }

        .main-body{
            padding: 40px 30px;
        }
        .option-field{
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            .password-icon{
                position: absolute;
                top: 13px;
                right: 16px;
            }
        }
        .custom-checkbox{
            width: 100%;
            display: block;
            margin-bottom: 45px;
        }
    }
}

.blockuser-modal{
    margin-top: 40px;
    h6{
        color: #FE0000;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; 
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }
    a{
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; 
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:991px){
    .login .login-card{
        padding: 20px;
    }
    .login .login-card h6{
        font-size: 18px;
        text-align: center;
    }
    .login{
        padding-left: 15px;
        padding-right: 15px;
    }
    .blockuser-modal h6{
        font-size: 18px;
    }
    .blockuser-modal a{
        font-size: 18px;
    }
}